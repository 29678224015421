.ABB_CommonUX_NotificationContainer__root {
  z-index: 999999 !important;
}
.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner
  > div:first-child {
  height: unset !important;
  min-height: 48px !important;
}
.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner
  > div:first-child
  > .ABB_CommonUX_Notification__text {
  word-wrap: break-word;
}
