.App{
    padding: 10px;

}

.menuOptions{
  padding: 10px;
  border-radius:5px;
  display:block
}

.menuOptions.active {
    background:#d1ebff;
}