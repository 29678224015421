.RoutesTable{
    width:100%;
    /* margin-top: 10px; */
    text-align: left;
    height: fit-content;
}

 .RoutesTable, th, td {
    border-bottom: 1px solid #f0f0f0;
    border-collapse: collapse;
    font-family: 'ABBVoice';
    font-size: 15px;
  } 



.RoutesTable tr td {
    font-weight: normal;
    padding: 15px 5px;
}

.RoutesTable td{
    width: 25%;
}

.sidePannel{
    /* transition: 0.3s; */
    overflow: hidden;
   height: 0px;
   
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.sidePannel label{
    font-size: 15px;
}
select{
    padding: 5px 8px;
    border-radius: 5px;
    width: 200px;
}