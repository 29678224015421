.App{
    padding: 10px;
    font-family: 'ABBVoice';
}

.inventoryApp{
    display: contents;
}

.inventoryList{
    height: 88%;
    overflow: auto;
    font-family: 'ABBVoice';
}