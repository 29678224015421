.footer-wrap {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ebebeb;
  text-align: center;
  height: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 0px 10px;
  /* z-index: 1000000; */
}
.dark-view-container .footer-wrap {
  background-color: #333;
  color: #fff;
}
.copyright-label {
  font-size: 12px;
  font-weight: 400;
  padding-left: 20px;
}
.abb-footer-logo {
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  margin-right: 35px;
}
