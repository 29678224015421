.TextHeading {
    font-size:22px;
    /* font-weight: 700; */
    font-family: ABBVoice-bold;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    /* position: absolute; */
    height: 24px;

}