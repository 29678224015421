.App {
    padding: 10px;
}
.detailsApp{
    display: contents;
}
.edgeDetails {
    display: flex;
    flex-direction: row;
    /* margin-top: 10px; */
    /* justify-content: center; */
    align-items: flex-start;
    overflow: auto;
    height: 87%;
    padding: 10px;
    padding-top: 0px;
}

.edgeDetails .detail {
   
    margin-top: 3px;
    /* letter-spacing: 1.5px; */
    text-align: justify;
    font-size: 15px;
    font-family: 'ABBVoice';
    
}

.leftsection,
.rightsection {
    display: flex;
    flex-direction: column;

}

.leftsection {
    padding-right: 20px;

}

.rightsection {
    padding-left: 20px;

}

.edgeDetails .heading {
    margin-top: 20px;
    font-size: 18px;
   
}

.heading{
    font-family: 'ABBVoice-bold';
}
.leftsection > .heading:first-of-type{
    margin-top: 10px;
}

