.header {
  display: flex;
  align-items: center;
  padding: 0px 17px;
  color: #333;
  /* background-color:#ececec; */
}

.menu-icon,
.notification-icon,
.profile-icon {
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
}

.notification-icon {

  position: relative;
  
}

.newNotificationContainer{
  background: #d93616;
  color: #fff;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 20px;
  font-weight: 700;
 }

.ant-popover-inner{
  padding: 0px !important;
}

.logo img {
  padding-left: 20px;
  width: 50px;
  height: 20px;
  margin-right: 10px;
  margin-bottom: -5px;
}

.divider {
  height: 24px;
  width: 1px;
  margin: 0 10px;
}

.app-name {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
  border-left: 2px solid #d5d5d5;
  padding-left: 10px;
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.spacer {
  flex-grow: 1;
}

.user-name {
  padding-right: 10px;
  padding-bottom: 3px;
  font-family: 'ABBVoice';
  font-weight: bold;
  
}

/* Navigation CSS */

.demo {
  display: flex;
  font-weight: 600;
  font-family: 'ABBVoice';
}

.demo-nav a {
  padding: 0 8px;
  line-height: 30px;
  font-family: 'ABBVoice';
}

.app-list {
  margin-top: 16px;
  font-family: 'ABBVoice';
}

.ant-breadcrumb-link span {
  border-radius: 5px;
  padding: 2px 3px;
  font-family: 'ABBVoice';
}

.ant-breadcrumb-link a, .ant-breadcrumb-link {
 
  font-family: 'ABBVoice';
}


.ant-breadcrumb-link span:hover {
  background-color: #dfdfdf;
  color: #000;

}

.ant-breadcrumb-separator {
  margin-top: 3px;
  font-family: 'ABBVoice';
}
