.App {
    padding: 10px;
}

.podStatusApp {
    padding: 0px !important;
    font-family: 'ABBVoice';
    display: contents;
}

.podStatusApp table {
    font-family: 'ABBVoice';
}

.podsTable th {
    font-size: 18px;
}


.podsTable .ant-table-body {
    overflow-y: auto !important;
}