.App{
    padding: 10px;
}
.quickLinks-container{
    margin-top: 30px;
    

    column-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 20px;
}
.linkicons{
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.headingLinks{
    padding: 20px;
}