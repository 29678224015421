body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__header {
  display: flex !important;
  justify-content: center;
}

.ABB_CommonUX_LoginScreen__root {
  background-position: left top !important;
  width: 100vw !important;
}

.ABB_CommonUX_LoginScreen__root {
  height: 100vh !important;
}

.ABB_CommonUX_LoginScreen__header {
  font-weight: 400;
  font-size: 14px;
}

.login-abb-logo {
  right: 0;
  margin-right: 38px;
  top: 40px;
  position: absolute;
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__header>.ABB_CommonUX_LoginScreen__logo {
  /* background: url(/images/logo.png) no-repeat; */
  background-size: contain;
  display: block;
}


.ABB_CommonUX_Input__root:not(.ABB_CommonUX_Input__isTextarea)>.ABB_CommonUX_Input__inputWrapper:after {
  height: 0px;
}

.ABB_CommonUX_Input__isInvalid .ABB_CommonUX_Input__inputWrapper {
  border-bottom-width: 2px;
  border-bottom-color: #f03040 !important;
}

.ABB_CommonUX_Table__root>tbody>tr>td {
  line-height: unset;
}


.ABB_CommonUX_Tooltip__container {
  font-family: 'ABBVoice';
}

.ABB_CommonUX_Tooltip__content {
  padding: 8px;
  text-align: center;
}

.ABB_CommonUX_Tooltip__content p {
  margin-top: 0px;
  margin-bottom: 0px;
}