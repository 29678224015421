.App{
    padding: 10px;
}

.dashboardDetailApp{
    display: contents;
}
.dashboardDetails{
    display: flex;
    margin-top: -7px;
    justify-content: center;
    align-items: center;
    height: 70%;
}
.dashboardDetails .detail{
    /* font-weight: 500; */
    margin-top: 2px;
    font-size: 18px;
}
.section{
    display: flex;
    column-gap: 40px;
   align-items: center;
   justify-content: space-evenly;
    /* background: red; */
    width: 100%;
    margin-left:-69px ;
}

.dashboardDetails .heading{
    /* margin-top: 20px; */
    font-weight: 700;
    font-size: 18px;
}

