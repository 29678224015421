.App {
  padding: 10px;
  overflow: hidden;
  transition: 0.3s;
}

/* .leftPieChart::after {
  height: 20px;
  width: 5px;
  background: #fff;
  content: "";
  display: block;
  position: absolute;
  right: -3px;
  bottom: 0px;
}

.leftPieChart::before {
  height: 50px;
  width: 5px;
  background: #fff;
  content: "";
  display: block;
  position: absolute;
  right: -3px;
  top: 0px;
} */

.leftPieChart,
.rightPieChart {
  width: 50%;
  height: fit-content;
}

.leftPieChart {
  /* border-right: 0.1px solid #e5e5e5; */
  /* position: relative; */
}

.chartHeading {
  font-weight: 500;
  position: absolute;
 
}

.piechartContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: -15px;
}

.colorReceptor {
  height: 10px;
  display: block;
  width: 10px;
  border-radius: 3px;
  flex: none;
}

.eachStat{
    display: flex;
    align-items: baseline;
    flex-direction: row;
    column-gap: 5px;
    /* width: 45%;
    font-size: 14px;
    overflow: hidden; */
}

.statWrapper{
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

    margin-top: 10px;
}

.legendText{
  display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    font-size: 15px;
    text-transform: capitalize;
}