.App{
    padding: 10px;
}

.ant-popover-inner .Heading_container{
   width: 93% !important;
}


.ant-form  label{
    font-size: 15px !important;
    font-family: "ABBVoice";
}


.ant-form  input{
    font-size: 15px !important;
    font-family: "ABBVoice";
}